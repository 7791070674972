    a{
        text-decoration: underline;
        color: blue;
    }
    .err-mssg{
        color: red;
        font-size: x-small;
        font-weight: 500;
    }

@media screen  and (max-width: 480px) {


    *{
        box-sizing: border-box;
    }

    html,body{
        height: 100%;
        width: 100%;
        background-color: #f9f9f9;
    }

    .form-title{
        font-size: 2.7vh;
        width: 100%;
        padding: 10px;
        position: absolute;
        top: 0;

        color: #fff;
        font-weight: bold;
        text-align: center;
    }

    .form-intro{
        font-size: 2.5vh;
        width: 100%;
        padding: 10px 0 10px 0;
        color: blue;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;
        float: left;
        text-align: left;
    }

    .user-form{
        height: 100vh;
        width: 100vw;
        padding: 0 10px 0 10px;
        background-color: blue;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .form-cnt{
        height: fit-content;
        width: 100%;
        padding: 20px;
        background-color: #fff;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: flex-start;
        flex-direction: column;
    }

    .input-cnt{
        height: 50px;
        margin: 10px 0 10px 0;
        width: 100%;

    }

    .input-cnt input{
        height: 100%;
        width: 100%;
        padding: 10px;
    }

    .input-cnt button{
        height: 100%;
        width: 100%;
        background-color: blue;
        outline: none;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        border: none;
    }

}

@media screen  and (min-width: 760px) {


    *{
        box-sizing: border-box;
    }

    html,body{
        height: 100%;
        width: 100%;
        background-color: #f9f9f9;
    }

    .form-title{
        font-size: 2.7vh;
        width: 100%;
        padding: 10px;
        position: absolute;
        top: 0;

        color: #fff;
        font-weight: bold;
        text-align: center;
    }

    .form-intro{
        font-size: 2.5vh;
        width: 100%;
        padding: 10px 0 10px 0;
        color: blue;
        font-family: 'Times New Roman', Times, serif;
        font-weight: bold;
        float: left;
        text-align: left;
    }

    .user-form{
        height: 100vh;
        width: 100vw;
        overflow: auto;
        padding: 0 10px 0 10px;
        background-color: blue;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .form-cnt{
        height: fit-content;
        width: 400px;
        padding: 20px;
        background-color: #fff;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: flex-start;
        flex-direction: column;
    }

    .input-cnt{
        height: 50px;
        margin: 10px 0 10px 0;
        width: 100%;

    }

    .input-cnt input{
        height: 100%;
        width: 100%;
        padding: 10px;
    }

    .input-cnt button{
        height: 100%;
        width: 100%;
        background-color: blue;
        outline: none;
        color: #fff;
        padding: 10px;
        border-radius: 5px;
        border: none;
    }

}